import React from 'react'
import "../stars.css"

function Stars() {
  return (
    <div id='stars'>
    <div className="stars"></div>
    <div className="stars2"></div>
    <div className="stars3"></div>
    </div>
  )
}

export default Stars